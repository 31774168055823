import React, { useRef } from 'react';
import { Formik, Form, FormikConfig, FormikProps } from 'formik';
import axios from 'axios';

import {
    container,
    proposalBox,
    proposalTitle,
    bundlesBox,
    bundleButton,
    proposalContent,
    servicesHeader,
    servicesHeaderLabel,
    servicesList,
    dataFields,
    comment,
    dataTitle,
    consentFields,
    loading,
} from './proposal-form.module.scss';
import { statusMap } from '../../config/status';
import { endpoints } from '../../config/endpoints';
import { IConsent } from '../../models/consent';
import { IProposal, IProposalBundle } from '../../models/proposal.model';
import { IProposalFormValues } from '../../models/proposal-form.model';
import { TStatus } from '../../models/status.model';
import {
    getProposalFormSchema,
    getProposalFormValues,
    emptyService,
} from '../../formik/proposal.form';
import { handleFormikErrors, TAlterpressAxiosError } from '../../utils/get-form-errors';
import { useT } from '../../hooks/use-translation';

import HandleFormikChange from '../hoc/handle-formik-change';
import Button from '../atoms/button';
import ProposalServiceInput from './proposal-service-input';
import Input from '../atoms/input';
import Consents from './consents';

export interface IProposalFormProps {
    className?: string;
    proposals: IProposal[];
    onStatusChange?(status: TStatus): void;
}

const ProposalForm: React.FC<IProposalFormProps> = ({
    className = '',
    proposals,
    onStatusChange,
}) => {
    const { t } = useT();
    const formikRef = useRef<FormikProps<IProposalFormValues>>(null);

    const handleBundleSelect = (
        formik: FormikProps<IProposalFormValues>,
        bundle?: IProposalBundle,
        proposalIndex?: number
    ) => {
        return () => {
            let newValues = formik.values;
            if (bundle && (proposalIndex || proposalIndex === 0)) {
                newValues = getProposalValuesForSelectedBundle({
                    values: formik.values,
                    proposalIndex,
                    bundle,
                    proposals,
                });
            }
            if (!bundle) {
                newValues = getClearProposalValues(formik.values);
            }
            formik.setValues(newValues);
        };
    };

    const handleChange = (values: IProposalFormValues) => {
        values.proposals.forEach((proposal, proposalIndex) => {
            // check whether the selected services, quantities and options correspond to any of the proposal bundles
            const selectedServices = getSelectedServices(proposal.services);
            const selectedBundle = getBundleMatchingServices(
                selectedServices,
                proposals[proposalIndex].bundles
            );

            // set the bundle id accordingly
            formikRef.current?.setFieldValue(
                `proposals.${proposalIndex}.bundleId`,
                selectedBundle?.bundleId || ''
            );
        });
    };

    const handleSubmit: FormikConfig<IProposalFormValues>['onSubmit'] = async (values, helpers) => {
        helpers.setStatus(statusMap.loading);
        if (typeof onStatusChange === 'function') {
            onStatusChange(statusMap.loading);
        }
        const data = getFormattedFetchData(values);
        try {
            await axios.post(`${process.env.API_URL}${endpoints.proposals.create}`, data);
            // helpers.resetForm();
            helpers.setStatus(statusMap.success);
            if (typeof onStatusChange === 'function') {
                onStatusChange(statusMap.success);
            }
        } catch (err) {
            handleFormikErrors<IProposalFormValues>(helpers, err as TAlterpressAxiosError, {
                global: t('form.error.global'),
                network: t('form.error.network'),
            });
            helpers.setStatus(statusMap.error);
            if (typeof onStatusChange === 'function') {
                onStatusChange(statusMap.error);
            }
        }
    };

    return (
        <Formik
            innerRef={formikRef}
            initialValues={getProposalFormValues(consents, proposals)}
            validationSchema={getProposalFormSchema(t, consents)}
            onSubmit={handleSubmit}
        >
            {(formik) => (
                <Form
                    className={`${container} ${className} ${
                        formik.status === statusMap.loading ? loading : ''
                    }`}
                >
                    <HandleFormikChange onChange={handleChange} />
                    {proposals.map((proposal, proposalIndex) => {
                        return (
                            <div className={proposalBox} key={`proposal-${proposal.proposalId}`}>
                                <h2 className={proposalTitle}>{proposal.name}</h2>
                                <div className={proposalContent}>
                                    {proposal.bundles && proposal.bundles.length > 0 && (
                                        <div className={bundlesBox}>
                                            {proposal.bundles.map((bundle) => {
                                                const isBundleActive =
                                                    formik.values.proposals[proposalIndex]
                                                        .bundleId === bundle.bundleId;
                                                return (
                                                    <Button
                                                        type="button"
                                                        key={`bundle-${bundle.bundleId}`}
                                                        onClick={handleBundleSelect(
                                                            formik,
                                                            bundle,
                                                            proposalIndex
                                                        )}
                                                        className={bundleButton}
                                                        {...(isBundleActive
                                                            ? {
                                                                  color: 'yellow',
                                                                  kind: 'normalDark',
                                                              }
                                                            : {
                                                                  kind: 'outline',
                                                                  color: 'light',
                                                              })}
                                                        size="small"
                                                    >
                                                        {bundle.name}
                                                    </Button>
                                                );
                                            })}
                                            <Button
                                                type="button"
                                                onClick={handleBundleSelect(formik)}
                                                className={bundleButton}
                                                {...(!formik.values.proposals[proposalIndex]
                                                    .bundleId
                                                    ? {
                                                          color: 'yellow',
                                                          kind: 'normalDark',
                                                      }
                                                    : {
                                                          kind: 'outline',
                                                          color: 'light',
                                                      })}
                                                size="small"
                                            >
                                                {t('proposal.bundle.custom')}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <div className={servicesHeader}>
                                        <p className={servicesHeaderLabel}>
                                            {t('proposal.service.header.name')}
                                        </p>
                                        <p>{t('proposal.service.header.quantity')}</p>
                                    </div>
                                    <div className={servicesList}>
                                        {proposal.services.map((service, serviceIndex) => {
                                            return (
                                                <ProposalServiceInput
                                                    key={`service-input-${service.serviceId}`}
                                                    name={`proposals.${proposalIndex}.services.${serviceIndex}`}
                                                    service={service}
                                                />
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                    <h2 className={dataTitle}>{t('proposal.form.data.title')}</h2>
                    <div className={dataFields}>
                        <Input
                            name="client.projectName"
                            label={t('proposal.form.projectName.label')}
                            placeholder={t('proposal.form.projectName.placeholder')}
                        />
                        <Input
                            name="client.email"
                            type="email"
                            label={t('proposal.form.email.label')}
                            placeholder={t('proposal.form.email.placeholder')}
                        />
                        <Input
                            name="client.phone"
                            type="tel"
                            label={t('proposal.form.phone.label')}
                            placeholder={t('proposal.form.phone.placeholder')}
                        />
                        <Input
                            className={comment}
                            name="client.comment"
                            as="textarea"
                            rows={10}
                            label={t('proposal.form.comment.label')}
                            placeholder={t('proposal.form.comment.placeholder')}
                        />
                    </div>
                    <Consents className={consentFields} consents={consents} theme="dark" />
                    <Button color="yellow" kind="normalDark">
                        {t('proposal.form.submit')}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

function getFormattedFetchData(values: IProposalFormValues) {
    return {
        ...values,
        proposals: values.proposals.map((proposal) => {
            return {
                ...proposal,
                services: proposal.services
                    .map((service) => {
                        return {
                            ...service,
                            id: Array.isArray(service.id) ? service.id[0] : service.id,
                            options: Array.isArray(service.options)
                                ? service.options
                                : [service.options],
                        };
                    })
                    .filter((service) => service.id),
            };
        }),
    };
}

function getClearProposalValues(values: IProposalFormValues) {
    return {
        ...values,
        proposals: values.proposals.map((proposal) => {
            return {
                ...proposal,
                bundleId: '',
                services: proposal.services.map(() => {
                    return emptyService;
                }),
            };
        }),
    };
}

interface IGetProposalValuesForSelectedBundleConfig {
    values: IProposalFormValues;
    proposals: IProposal[];
    bundle: IProposalBundle;
    proposalIndex: number;
}

function getProposalValuesForSelectedBundle({
    values,
    proposals,
    bundle,
    proposalIndex,
}: IGetProposalValuesForSelectedBundleConfig) {
    return {
        ...values,
        proposals: values.proposals.map((formProposal, index) => {
            if (index !== proposalIndex) return formProposal;
            const proposal = proposals[proposalIndex];
            return {
                ...formProposal,
                bundleId: bundle.bundleId,
                services: proposal.services.map((service) => {
                    const bundleService = bundle?.services.find(
                        (bundleService) => bundleService.serviceId === service.serviceId
                    );
                    if (bundleService) {
                        return {
                            id: [service.serviceId.toString()],
                            quantity: bundleService.quantity,
                            options: bundleService.options,
                        };
                    }
                    return emptyService;
                }),
            };
        }),
    };
}

function getSelectedServices(services: IProposalFormValues['proposals'][0]['services']) {
    return services.filter((service) => {
        if (Array.isArray(service.id)) return service.id.length > 0;
        return service.id;
    });
}

function getBundleMatchingServices(
    services: IProposalFormValues['proposals'][0]['services'],
    bundles: IProposalBundle[]
) {
    return bundles.find((bundle) => {
        return (
            bundle.services.length === services.length &&
            bundle.services.every((bundleService) => {
                return services.some((service) => {
                    const serviceOptions = Array.isArray(service.options)
                        ? service.options
                        : [service.options];
                    const serviceId = Array.isArray(service.id) ? service.id[0] : service.id || '';
                    return (
                        serviceId?.toString() === bundleService.serviceId.toString() &&
                        service.quantity === bundleService.quantity &&
                        bundleService.options.join('') === serviceOptions.join('')
                    );
                });
            })
        );
    });
}

const consents: IConsent[] = [
    { name: 'wantNewsletter' },
    { name: 'consentRegulationsAndPrivacyPolicy', required: true },
];

export default ProposalForm;
